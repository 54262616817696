/* Mixins */
/* Groups animations to only apply them
   When the element is in view.
   Also disables animations on devices featuing reduced motion.
 */
/* Maintains element aspect ratio 
*/
/* Orientation 
   - Target device orientation such as portrait or landscape
*/
/* Retina 
   - Target high resolution / retina devices.
   Useful for displaying high resolution graphics to devices that
   can support them. Retina will also target print as retina is designed
   not to change content but make it sharper.
*/
/* Reduced Motion 
   - Target devices where the user has set their preference for reduced motion.
   Animation mixins & functions already account for reduced motion and block animations.
*/
/* Print
   - Target print mode. Useful for hiding content that
   is not relevant on a printed page. Example a tags could be replaced with the actual urls.
*/
/* Standalone
   - Target standalone mode. Useful for changing content based on if a PWA is installed.
*/
/* Responsive Font based off breakpoints */
/* Image background */
/* Mixins */
/* Groups animations to only apply them
   When the element is in view.
   Also disables animations on devices featuing reduced motion.
 */
/* Maintains element aspect ratio 
*/
/* Orientation 
   - Target device orientation such as portrait or landscape
*/
/* Retina 
   - Target high resolution / retina devices.
   Useful for displaying high resolution graphics to devices that
   can support them. Retina will also target print as retina is designed
   not to change content but make it sharper.
*/
/* Reduced Motion 
   - Target devices where the user has set their preference for reduced motion.
   Animation mixins & functions already account for reduced motion and block animations.
*/
/* Print
   - Target print mode. Useful for hiding content that
   is not relevant on a printed page. Example a tags could be replaced with the actual urls.
*/
/* Standalone
   - Target standalone mode. Useful for changing content based on if a PWA is installed.
*/
/* Responsive Font based off breakpoints */
/* Image background */
.container {
  box-sizing: border-box;
  max-width: 1620px;
  margin: 0 auto;
  padding-right: 2%;
  padding-left: 2%; }
  .container.fluid {
    max-width: none;
    width: auto;
    height: 100%;
    width: 100%; }
    .container.fluid > .row {
      margin-right: 0;
      margin-left: 0; }
  .container.neat {
    padding-right: 0px;
    padding-left: 0px; }
  .container.neat-left {
    padding-left: 0px; }
  .container.neat-right {
    padding-right: 0px; }
  .container.super.fluid {
    width: auto;
    max-width: none; }
  .container.fluid-width {
    width: 100%;
    max-width: none;
    padding-right: 2%;
    padding-left: 2%; }
  .container .row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -0.5%;
    margin-left: -0.5%; }
    .container .row.reverse {
      flex-direction: row-reverse; }
    .container .row.neat {
      margin: 0px; }
  .container *[class*='col'].neat {
    padding: 0px; }
  .container *[class*='col'].fluid,
  .container .row.fluid {
    height: 100%; }
  .container .col.reverse {
    flex-direction: column-reverse; }
  .container *[class*='col'].padded,
  .container .row.padded,
  .container .container.padded {
    padding-left: 15px;
    padding-right: 15px; }
  .container *[class*='col'].very-neat,
  .container .row.very-neat,
  .container .container.very-neat {
    margin: 0px;
    padding: 0px; }
  .container *[class*='col'].flex-row,
  .container .row.flex-row,
  .container .container.flex-row {
    display: flex; }
  .container *[class*='col'].flex-column,
  .container .row.flex-column,
  .container .container.flex-column {
    display: flex;
    flex-direction: column; }

.col-xs-0 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 0%;
  max-width: 0%; }

.col-xs-offset-0 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 0; }

.order-xs-0 {
  order: 0; }

.col-xs-1 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-offset-1 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 8.33333%; }

.order-xs-1 {
  order: 1; }

.col-xs-2 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-offset-2 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 16.66667%; }

.order-xs-2 {
  order: 2; }

.col-xs-3 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-offset-3 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 25%; }

.order-xs-3 {
  order: 3; }

.col-xs-4 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-offset-4 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 33.33333%; }

.order-xs-4 {
  order: 4; }

.col-xs-5 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-offset-5 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 41.66667%; }

.order-xs-5 {
  order: 5; }

.col-xs-6 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-offset-6 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 50%; }

.order-xs-6 {
  order: 6; }

.col-xs-7 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-offset-7 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 58.33333%; }

.order-xs-7 {
  order: 7; }

.col-xs-8 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-offset-8 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 66.66667%; }

.order-xs-8 {
  order: 8; }

.col-xs-9 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-offset-9 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 75%; }

.order-xs-9 {
  order: 9; }

.col-xs-10 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-offset-10 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 83.33333%; }

.order-xs-10 {
  order: 10; }

.col-xs-11 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-offset-11 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 91.66667%; }

.order-xs-11 {
  order: 11; }

.col-xs-12 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-12 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  margin-left: 100%; }

.order-xs-12 {
  order: 12; }

.container {
  width: 1; }

.padded-xs {
  padding-left: 15px;
  padding-right: 15px; }

.col-xs {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  flex-basis: auto;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.hidden-xs {
  visibility: hidden;
  position: absolute;
  left: -9999; }

.show-xs {
  visibility: visible;
  position: unset;
  left: unset; }

.start-xs {
  justify-content: flex-start;
  text-align: left; }

.center-xs {
  justify-content: center;
  text-align: center; }

.end-xs {
  justify-content: flex-end;
  text-align: right; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  align-items: space-around; }

.between-xs {
  align-items: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 54.85714em) {
  .col-sm-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 0%;
    max-width: 0%; }
  .col-sm-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 0; }
  .order-sm-0 {
    order: 0; }
  .col-sm-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 8.33333%; }
  .order-sm-1 {
    order: 1; }
  .col-sm-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 16.66667%; }
  .order-sm-2 {
    order: 2; }
  .col-sm-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 25%; }
  .order-sm-3 {
    order: 3; }
  .col-sm-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 33.33333%; }
  .order-sm-4 {
    order: 4; }
  .col-sm-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 41.66667%; }
  .order-sm-5 {
    order: 5; }
  .col-sm-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 50%; }
  .order-sm-6 {
    order: 6; }
  .col-sm-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 58.33333%; }
  .order-sm-7 {
    order: 7; }
  .col-sm-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 66.66667%; }
  .order-sm-8 {
    order: 8; }
  .col-sm-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 75%; }
  .order-sm-9 {
    order: 9; }
  .col-sm-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 83.33333%; }
  .order-sm-10 {
    order: 10; }
  .col-sm-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 91.66667%; }
  .order-sm-11 {
    order: 11; }
  .col-sm-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 100%; }
  .order-sm-12 {
    order: 12; }
  .container {
    width: 52.57143rem; }
  .padded-sm {
    padding-left: 15px;
    padding-right: 15px; }
  .col-sm {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: auto;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .hidden-sm {
    visibility: hidden;
    position: absolute;
    left: -9999; }
  .show-sm {
    visibility: visible;
    position: unset;
    left: unset; }
  .start-sm {
    justify-content: flex-start;
    text-align: left; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  .end-sm {
    justify-content: flex-end;
    text-align: right; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    align-items: space-around; }
  .between-sm {
    align-items: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 70.85714em) {
  .col-md-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 0%;
    max-width: 0%; }
  .col-md-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 0; }
  .order-md-0 {
    order: 0; }
  .col-md-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 8.33333%; }
  .order-md-1 {
    order: 1; }
  .col-md-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 16.66667%; }
  .order-md-2 {
    order: 2; }
  .col-md-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 25%; }
  .order-md-3 {
    order: 3; }
  .col-md-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 33.33333%; }
  .order-md-4 {
    order: 4; }
  .col-md-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 41.66667%; }
  .order-md-5 {
    order: 5; }
  .col-md-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 50%; }
  .order-md-6 {
    order: 6; }
  .col-md-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 58.33333%; }
  .order-md-7 {
    order: 7; }
  .col-md-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 66.66667%; }
  .order-md-8 {
    order: 8; }
  .col-md-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 75%; }
  .order-md-9 {
    order: 9; }
  .col-md-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 83.33333%; }
  .order-md-10 {
    order: 10; }
  .col-md-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 91.66667%; }
  .order-md-11 {
    order: 11; }
  .col-md-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 100%; }
  .order-md-12 {
    order: 12; }
  .container {
    width: 69.71429rem; }
  .padded-md {
    padding-left: 15px;
    padding-right: 15px; }
  .col-md {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: auto;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .hidden-md {
    visibility: hidden;
    position: absolute;
    left: -9999; }
  .show-md {
    visibility: visible;
    position: unset;
    left: unset; }
  .start-md {
    justify-content: flex-start;
    text-align: left; }
  .center-md {
    justify-content: center;
    text-align: center; }
  .end-md {
    justify-content: flex-end;
    text-align: right; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    align-items: space-around; }
  .between-md {
    align-items: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 85.71429em) {
  .col-lg-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 0%;
    max-width: 0%; }
  .col-lg-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 0; }
  .order-lg-0 {
    order: 0; }
  .col-lg-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 8.33333%; }
  .order-lg-1 {
    order: 1; }
  .col-lg-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 16.66667%; }
  .order-lg-2 {
    order: 2; }
  .col-lg-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 25%; }
  .order-lg-3 {
    order: 3; }
  .col-lg-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 33.33333%; }
  .order-lg-4 {
    order: 4; }
  .col-lg-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 41.66667%; }
  .order-lg-5 {
    order: 5; }
  .col-lg-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 50%; }
  .order-lg-6 {
    order: 6; }
  .col-lg-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 58.33333%; }
  .order-lg-7 {
    order: 7; }
  .col-lg-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 66.66667%; }
  .order-lg-8 {
    order: 8; }
  .col-lg-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 75%; }
  .order-lg-9 {
    order: 9; }
  .col-lg-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 83.33333%; }
  .order-lg-10 {
    order: 10; }
  .col-lg-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 91.66667%; }
  .order-lg-11 {
    order: 11; }
  .col-lg-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 100%; }
  .order-lg-12 {
    order: 12; }
  .container {
    width: 81.14286rem; }
  .padded-lg {
    padding-left: 15px;
    padding-right: 15px; }
  .col-lg {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: auto;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .hidden-lg {
    visibility: hidden;
    position: absolute;
    left: -9999; }
  .show-lg {
    visibility: visible;
    position: unset;
    left: unset; }
  .start-lg {
    justify-content: flex-start;
    text-align: left; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  .end-lg {
    justify-content: flex-end;
    text-align: right; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    align-items: space-around; }
  .between-lg {
    align-items: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

@media only screen and (min-width: 114.28571em) {
  .col-xl-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 0%;
    max-width: 0%; }
  .col-xl-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 0; }
  .order-xl-0 {
    order: 0; }
  .col-xl-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-xl-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 8.33333%; }
  .order-xl-1 {
    order: 1; }
  .col-xl-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-xl-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 16.66667%; }
  .order-xl-2 {
    order: 2; }
  .col-xl-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-xl-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 25%; }
  .order-xl-3 {
    order: 3; }
  .col-xl-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-xl-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 33.33333%; }
  .order-xl-4 {
    order: 4; }
  .col-xl-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-xl-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 41.66667%; }
  .order-xl-5 {
    order: 5; }
  .col-xl-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-xl-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 50%; }
  .order-xl-6 {
    order: 6; }
  .col-xl-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-xl-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 58.33333%; }
  .order-xl-7 {
    order: 7; }
  .col-xl-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-xl-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 66.66667%; }
  .order-xl-8 {
    order: 8; }
  .col-xl-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-xl-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 75%; }
  .order-xl-9 {
    order: 9; }
  .col-xl-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-xl-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 83.33333%; }
  .order-xl-10 {
    order: 10; }
  .col-xl-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-xl-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 91.66667%; }
  .order-xl-11 {
    order: 11; }
  .col-xl-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-xl-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 100%; }
  .order-xl-12 {
    order: 12; }
  .container {
    width: 96.14286rem; }
  .padded-xl {
    padding-left: 15px;
    padding-right: 15px; }
  .col-xl {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: auto;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .hidden-xl {
    visibility: hidden;
    position: absolute;
    left: -9999; }
  .show-xl {
    visibility: visible;
    position: unset;
    left: unset; }
  .start-xl {
    justify-content: flex-start;
    text-align: left; }
  .center-xl {
    justify-content: center;
    text-align: center; }
  .end-xl {
    justify-content: flex-end;
    text-align: right; }
  .top-xl {
    align-items: flex-start; }
  .middle-xl {
    align-items: center; }
  .bottom-xl {
    align-items: flex-end; }
  .around-xl {
    align-items: space-around; }
  .between-xl {
    align-items: space-between; }
  .first-xl {
    order: -1; }
  .last-xl {
    order: 1; } }

@media only screen and (min-width: 128.57143em) {
  .col-tv-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 0%;
    max-width: 0%; }
  .col-tv-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 0; }
  .order-tv-0 {
    order: 0; }
  .col-tv-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-tv-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 8.33333%; }
  .order-tv-1 {
    order: 1; }
  .col-tv-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-tv-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 16.66667%; }
  .order-tv-2 {
    order: 2; }
  .col-tv-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-tv-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 25%; }
  .order-tv-3 {
    order: 3; }
  .col-tv-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-tv-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 33.33333%; }
  .order-tv-4 {
    order: 4; }
  .col-tv-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-tv-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 41.66667%; }
  .order-tv-5 {
    order: 5; }
  .col-tv-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-tv-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 50%; }
  .order-tv-6 {
    order: 6; }
  .col-tv-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-tv-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 58.33333%; }
  .order-tv-7 {
    order: 7; }
  .col-tv-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-tv-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 66.66667%; }
  .order-tv-8 {
    order: 8; }
  .col-tv-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-tv-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 75%; }
  .order-tv-9 {
    order: 9; }
  .col-tv-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-tv-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 83.33333%; }
  .order-tv-10 {
    order: 10; }
  .col-tv-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-tv-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 91.66667%; }
  .order-tv-11 {
    order: 11; }
  .col-tv-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-tv-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    margin-left: 100%; }
  .order-tv-12 {
    order: 12; }
  .container {
    width: 131.85714rem; }
  .padded-tv {
    padding-left: 15px;
    padding-right: 15px; }
  .col-tv {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5%;
    padding-left: 0.5%;
    flex-basis: auto;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .hidden-tv {
    visibility: hidden;
    position: absolute;
    left: -9999; }
  .show-tv {
    visibility: visible;
    position: unset;
    left: unset; }
  .start-tv {
    justify-content: flex-start;
    text-align: left; }
  .center-tv {
    justify-content: center;
    text-align: center; }
  .end-tv {
    justify-content: flex-end;
    text-align: right; }
  .top-tv {
    align-items: flex-start; }
  .middle-tv {
    align-items: center; }
  .bottom-tv {
    align-items: flex-end; }
  .around-tv {
    align-items: space-around; }
  .between-tv {
    align-items: space-between; }
  .first-tv {
    order: -1; }
  .last-tv {
    order: 1; } }

.\--developer *[class*='col'] {
  background-clip: content-box;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23a3a3a3' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E"); }

.\--developer *[class*='row'] {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23666666' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  background-clip: content-box; }

.\--developer *[class*='wrapper'] {
  background-clip: content-box; }

/* Used for development */
.storybook-layout {
  width: 100%; }

h1.responsive {
  font-size: 16px; }
  @media only screen and (min-width: 54.85714em) {
    h1.responsive {
      font-size: 16px;
      line-height: 24px; } }
  @media only screen and (min-width: 70.85714em) {
    h1.responsive {
      font-size: 32px;
      line-height: 48px; } }
  @media only screen and (min-width: 85.71429em) {
    h1.responsive {
      font-size: 48px;
      line-height: 72px; } }
  @media only screen and (min-width: 114.28571em) {
    h1.responsive {
      font-size: 64px;
      line-height: 96px; } }
  @media only screen and (min-width: 128.57143em) {
    h1.responsive {
      font-size: 80px;
      line-height: 120px; } }

h2.responsive {
  font-size: 14px; }
  @media only screen and (min-width: 54.85714em) {
    h2.responsive {
      font-size: 14px;
      line-height: 21px; } }
  @media only screen and (min-width: 70.85714em) {
    h2.responsive {
      font-size: 28px;
      line-height: 42px; } }
  @media only screen and (min-width: 85.71429em) {
    h2.responsive {
      font-size: 42px;
      line-height: 63px; } }
  @media only screen and (min-width: 114.28571em) {
    h2.responsive {
      font-size: 56px;
      line-height: 84px; } }
  @media only screen and (min-width: 128.57143em) {
    h2.responsive {
      font-size: 70px;
      line-height: 105px; } }

p.responsive {
  font-size: 12px; }
  @media only screen and (min-width: 54.85714em) {
    p.responsive {
      font-size: 12px;
      line-height: 18px; } }
  @media only screen and (min-width: 70.85714em) {
    p.responsive {
      font-size: 24px;
      line-height: 36px; } }
  @media only screen and (min-width: 85.71429em) {
    p.responsive {
      font-size: 30px;
      line-height: 45px; } }
  @media only screen and (min-width: 114.28571em) {
    p.responsive {
      font-size: 30px;
      line-height: 45px; } }
  @media only screen and (min-width: 128.57143em) {
    p.responsive {
      font-size: 30px;
      line-height: 45px; } }

@media screen and (orientation: portrait) {
  .orientation {
    color: purple; }
    .orientation:after {
      content: 'Portrait Orientation'; } }

@media screen and (orientation: landscape) {
  .orientation {
    color: green; }
    .orientation:after {
      content: 'Landscape Orientation'; } }

.retina:after {
  content: 'Standard'; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .retina {
    color: purple; }
    .retina:after {
      content: 'Retina'; } }

@media print {
  .retina {
    color: purple; }
    .retina:after {
      content: 'Retina'; } }

.reduced-motion:after {
  content: 'Normal Motion / Doesnt Support'; }

@media (prefers-reduced-motion: reduce) {
  .reduced-motion {
    color: purple; }
    .reduced-motion:after {
      content: 'Prefers reduced motion'; } }

.print:after {
  content: 'Visible on screen'; }

@media print {
  .print {
    color: purple; }
    .print:after {
      content: 'Visible on print'; } }

.clear-text {
  font: 0/0 a;
  color: transparent; }

.word-wrap {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  max-width: 300px; }
  .word-wrap:after {
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo, erat ut dapibus tincidunt, mauris metus auctor orci, at dignissim est nunc a neque. Nam at velit magna. Sed ornare nisl vitae volutpat tristique. Aenean tincidunt laoreet lorem ut dapibus. Pellentesque consectetur diam sapien, nec lobortis quam malesuada et. Fusce sodales, sem eget porttitor tempor, urna tortor interdum enim, sed efficitur libero sem vitae tortor. Fusce scelerisque, libero at laoreet consequat, ex felis auctor diam, ut convallis lacus tellus sit amet nisl. Maecenas id libero lacinia, facilisis risus non, ullamcorper velit.'; }

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px; }
  .ellipsis:after {
    content: 'Donec commodo, erat ut dapibus tincidunt, mauris metus auctor orci,'; }

.no-text {
  font: 0/0 a;
  color: transparent; }
  .no-text:after {
    content: 'Text is not visisble'; }

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .no-select:after {
    content: 'You are unable to select this text'; }

.image {
  background: url("http://retinaimag.es/img/settings-small.png") no-repeat;
  background-repeat: no-repeat;
  background-size: 59px 59px;
  background-position: center;
  height: 59px;
  width: 59px; }
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .image {
      background-image: url("http://retinaimag.es/img/settings-large.png"); } }

.article {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: Montserrat; }
  .article__header {
    padding: 10px;
    font-weight: bold;
    font-size: 20px; }
    @media only screen and (min-width: 54.85714em) {
      .article__header {
        font-size: 20px;
        line-height: 40px; } }
    @media only screen and (min-width: 70.85714em) {
      .article__header {
        font-size: 35px;
        line-height: 70px; } }
    @media only screen and (min-width: 85.71429em) {
      .article__header {
        font-size: 35px;
        line-height: 70px; } }
    @media only screen and (min-width: 114.28571em) {
      .article__header {
        font-size: 35px;
        line-height: 70px; } }
    @media only screen and (min-width: 128.57143em) {
      .article__header {
        font-size: 35px;
        line-height: 70px; } }
  .article__section {
    flex: 1;
    padding: 10px; }
  .article__footer {
    padding-top: 2em;
    padding-bottom: 10em; }
  .article.\--center {
    text-align: center; }
  .article.\--white {
    background-color: white; }
  .article__section {
    font-weight: normal;
    font-size: 20px;
    word-spacing: 0.25em;
    line-height: 30px; }
